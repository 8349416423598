CONTENT = {
	"structure": [
		{
			"id": "olguveoddosfimragjae",
			"label": "Challenge",
			"children": [
				{
					"id": "hamtirkuzrutreunaupn",
					"identifier": "intro",
					"label": "Raw data",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_01",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_01"
							}
						},
						{
							"type": "text",
							"value": "How do you really know your audience?"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Challenge_1_icon"
						}
					]
				},
				{
					"id": "lifedtadguzidonuwfal",
					"label": "Enrich data",
					"identifier": "intro dots with labels",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_02",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_03"
							}
						},
						{
							"type": "text",
							"value": "Add context beyond latitude and longitude"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Challenge_2_icon"
						}
					]
				}
			]
		},
		{
			"id": "ramefnagengalifjuvsu",
			"label": "Solution",
			"children": [
				{
					"id": "cininduigipvevnuvcir",
					"label": "Fresh & accurate",
					"identifier": "adding context",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_04",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_05"
							}
						},
						{
							"type": "text",
							"value": "• Best-in-class mapping\n• Powered by the HERE Open Location Platform"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Solution_1_icon"
						}
					]
				},
				{
					"id": "ijuwoewinazosvejbakz",
					"label": "Global scale",
					"identifier": "adding context flags and blocks",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_06",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_07"
							}
						},
						{
							"type": "text",
							"value": "126,000,000 places worldwide"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Solution_2_icon"
						}
					]
				},
				{
					"id": "wapedcubzudmofokkoti",
					"label": "Precise building polygons",
					"identifier": "adding context buildings",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_08",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_09"
							}
						},
						{
							"type": "text",
							"value": "Know the exact shapes of buildings"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Solution_3_icon"
						}
					]
				},
				{
					"id": "ciehesgamersuligwabu",
					"label": "Rich Places data",
					"identifier": "places labels",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_10",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_11"
							}
						},
						{
							"type": "text",
							"value": "• 400 categories\n• 260 attributes for rich decision-making"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Solution_4_icon"
						}
					]
				}
			]
		},
		{
			"id": "nacemadvufepnomcelho",
			"label": "Audience segmentation",
			"children": [
				{
					"id": "untasuvvodginpopinuv",
					"label": "Personalize",
					"identifier": "audience segmentation",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_12",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_13"
							}
						},
						{
							"type": "text",
							"value": "Build accurate, location-derived audience segments"
						},
						{
							"type": "image",
							"value": "AdTech_insight_MS1_1_icon"
						}
					]
				}
			]
		},
		{
			"id": "owairenuotogigrucari",
			"label": "Audience targeting",
			"children": [
				{
					"id": "ouhfgoiudhfguihdfsga",
					"identifier": "audience targeting"
				},
				{
					"id": "kuruwewrogfunoznapen",
					"label": "Engaging experiences",
					"identifier": "audience targeting deal",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_16",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_17"
							}
						},
						{
							"type": "text",
							"value": "Deliver meaningful and relevant campaigns"
						},
						{
							"type": "image",
							"value": "AdTech_insight_MS2_1_icon"
						}
					]
				},
				{
					"id": "korawadekzoflorudkat",
					"identifier": "audience targeting deal out"
				},
				{
					"id": "hilpekodkasidsauveri",
					"identifier": "audience targeting deal out",
					"label": "Additional parameters",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_18",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_19"
							}
						},
						{
							"type": "text",
							"value": "• Traffic\n• Weather\n• Time of day"
						},
						{
							"type": "image",
							"value": "AdTech_insight_MS2_2_icon"
						}
					]
				},
				{
					"id": "bolzomjofhizufvuzeko",
					"label": "Indoor capabilities",
					"identifier": "audience targeting deal out",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_19",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_19"
							}
						},
						{
							"type": "text",
							"value": "Continue the digital customer journey indoors"
						},
						{
							"type": "image",
							"value": "AdTech_insight_MS2_3_icon"
						}
					]
				}
			]
		},
		{
			"id": "bavirgujhokehtuznava",
			"label": "Attribution",
			"children": [
				{
					"id": "curoonipotzimacdipoj",
					"label": "Understanding footfall",
					"identifier": "attribution",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_20",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_21"
							}
						},
						{
							"type": "text",
							"value": "• Track campaign effectiveness \n• Optimize for future campaigns"
						},
						{
							"type": "image",
							"value": "AdTech_insight_MS3_1_icon"
						}
					]
				},
				{
					"id": "wakzohnunpocakjuizme",
					"label": "Geovisualization",
					"identifier": "attribution",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_21",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_21"
							}
						},
						{
							"type": "text",
							"value": "Visualize location data with relevant heatmaps"
						},
						{
							"type": "image",
							"value": "AdTech_insight_MS3_2_icon"
						}
					]
				}
			]
		},
		{
			"id": "jazuzajiigefejeevooz",
			"label": "Summary",
			"children": [
				{
					"id": "vopurtihakujsejantut",
					"label": "Positive results",
					"identifier": "zoom out",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_22",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_23"
							}
						},
						{
							"type": "text",
							"value": "More efficient and contextual mobile advertising campaigns"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Summary_1_icon"
						}
					]
				},
				{
					"id": "edusihetetcavfaokuzu",
					"label": "Data neutral",
					"identifier": "zoom out",
					"content_elements": [
						{
							"type": "video",
							"value": {
								"intro": "usecase_01_adtech_bcn_comp_003_hr_23",
								"loop": "usecase_01_adtech_bcn_comp_003_hr_23"
							}
						},
						{
							"type": "text",
							"value": "• Your global and neutral data provider\n• Facilitating smooth movement into new markets"
						},
						{
							"type": "image",
							"value": "AdTech_insight_Summary_2_icon"
						}
					]
				}
			]
		}
	]
}
